import React, {ReactElement} from "react";

import {withStyles} from '@mui/styles';

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppsIcon from '@mui/icons-material/Apps';
import PeopleIcon from "@mui/icons-material/People";
import RouterIcon from '@mui/icons-material/Router';
import HistoryIcon from '@mui/icons-material/History';
import RefreshIcon from "@mui/icons-material/Refresh";
import Console from "../../components/Console";

import {SnackbarOrigin, VariantType, withSnackbar, WithSnackbarProps,} from 'notistack';

import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Button,
    Container,
    CssBaseline,
    Divider,
    Drawer,
    Fade,
    FormHelperText,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Slide,
    Typography
} from "@mui/material";
import {Navigate} from "react-router-dom";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";

import {styles} from "./Styles";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    DMSMessageFactory,
    DMSMethod,
    DMSRESTApiClient,
    DMSWSClient,
    IDBNode,
    IDBNotificationSubscription,
    IDBUser,
    IDBUserGroup,
    IDBUserRole,
    IDMSBridgeNotification,
    IDMSClientConfig,
    IDMSClientDelegate,
    IDMSFile,
    IDMSMessage,
    IDMSNode,
    IDMSNodeBanState,
    IDMSResult,
    IDMSServerStats,
    IDMSSettings,
    IDMSSoftwareBundle,
    IDMSSoftwareUpdateProgress,
    IPortForwardingRule,
    Util
} from "dms_commons";

import SignalCellularConnectedNoInternet0BarTwoToneIcon
    from "@mui/icons-material/SignalCellularConnectedNoInternet0BarTwoTone";
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import Constants from "../../Constants";
import IDMSNodeManagingSession from "../../models/IDMSNodeManagingSession";
import moment from "moment";
import EndpointHelper from "../../helpers/EndpointHelper";
import DMSNodesView from "../../components/DMSNodesView";
import DMSFilesView from "../../components/DMSFilesView";
import {DmsConnectionState} from "../../enums/DmsConnectionState";
import {VIEW_TYPE} from "../../enums/VIEW_TYPE";
import DMSUsersView from "../../components/DMSUsersView";
import INodeInfoDialogState from "../../models/INodeInfoDialogState";
import Spinner from 'react-spinkit';
import DMSPortForwardingRulesView from "../../components/DMSPortForwardingRulesView";
import DMSUserActivityView from "../../components/DMSUserActivityView";
import DMSSettingsView from "../../components/DMSSettingsView";
import SettingsIcon from '@mui/icons-material/Settings';
import DMSUserView from "../../components/DMSUserView";
import {Api, Description, LocalHospital} from "@mui/icons-material";
import DMSHealthView from "../../components/DMSHealthView";
import AppHeader from "../../components/AppHeader";
import ModalDialog from "../../components/ModalDialog";
import ReactCodeInput from "react-verification-code-input";
import DMSDashView from "../../components/DMSDashView";
import DMSAPIClientsView from "../../components/DMSAPIClientsView";

export type TwoFactorRequest = {
    isLoading?: boolean,
    description?: string,
    onGotToken: (token: string) => void
    onCancelled: () => void;
};

interface IStateDataSource {
    dmsNodes: Map<string, IDBNode>;
    dmsFiles: IDMSFile[];
    dmsUsers: IDBUser[];
    apiUsers: IDBUser[];
    dmsOnlineNodes: Map<string, IDMSNode>;
    serverStatsExpanded: boolean;
    serverStats?: IDMSServerStats;
    userGroups?: IDBUserGroup[];
    userRoles?: IDBUserRole[];

    portRules: IPortForwardingRule[];
    definedSoftware: IDMSSoftwareBundle[];
    notificationSettings: IDBNotificationSubscription[];
    bannedNodes: IDMSNodeBanState[];
}

interface IStateLoading {
    isLoadingNodes: boolean;
    isLoadingFiles: boolean;
    isLoadingUsers: boolean;
    isLoadingUserGroups: boolean;
    isLoadingUserRoles: boolean;
    isLoadingNotificationSettings: boolean;
    isLoadingServerStats?: boolean;
    isLoadingPortRules: boolean;
    isLoadingDefinedSoftware: boolean;
    isLoadingBannedNodes: boolean;
}

interface IProps extends WithSnackbarProps {
    classes: any;
    selectedView?: VIEW_TYPE;
    viewerMode?: boolean;
    authToken?: string;
}

interface IState extends IStateDataSource, IStateLoading {
    globalSettings?: IDMSSettings;
    isInitializing: boolean;
    isDrawerOpen: boolean;
    isConsoleVisible: boolean;
    logItems: string[];
    currentUser?: IDBUser;
    consoleHeader?: string;
    userInitials: string;
    dmsConnectionState: DmsConnectionState;
    deletionTargetFile?: IDMSFile;
    selectedNodeInfoTab: number;
    selectedView: VIEW_TYPE;
    nodeManagingSession?: IDMSNodeManagingSession;
    nodeInfoDialogState?: INodeInfoDialogState;
    newLogEvents: number;
    password?: string;
    verify2FARequest?: TwoFactorRequest;
}

class App extends React.Component<IProps, IState> implements IDMSClientDelegate {
    public state: IState = {
        isInitializing: false,
        isDrawerOpen: false,
        isConsoleVisible: false,
        logItems: [],
        userInitials: "",
        dmsNodes: new Map<string, IDBNode>(),
        dmsFiles: [],
        dmsUsers: [],
        apiUsers: [],
        notificationSettings: [],
        dmsConnectionState: DmsConnectionState.INITIAL,
        selectedView: this.props.selectedView ?? LocalStorageHelper.getCurrentView(),
        selectedNodeInfoTab: 0,
        isLoadingFiles: false,
        isLoadingNodes: false,
        isLoadingUsers: false,
        isLoadingUserGroups: false,
        isLoadingUserRoles: false,
        isLoadingNotificationSettings: false,
        isLoadingPortRules: false,
        isLoadingDefinedSoftware: false,
        isLoadingBannedNodes: false,
        serverStatsExpanded: LocalStorageHelper.getServerStatsExpanded(),
        dmsOnlineNodes: new Map<string, IDBNode>(),
        portRules: [],
        bannedNodes: [],
        newLogEvents: 0,
        definedSoftware: [],
    };

    constructor(props: IProps) {
        super(props);

        const authToken = this.getJwtToken();
        this.hasAuthToken = Boolean(authToken);

        this.mainMenuItems.set(VIEW_TYPE.VIEW_NODES, "Nodes");
        this.mainMenuItems.set(VIEW_TYPE.VIEW_API, "API Clients");
        this.mainMenuItems.set(VIEW_TYPE.VIEW_FILES, "Files");
        this.mainMenuItems.set(VIEW_TYPE.VIEW_USERS, "Users");
        this.mainMenuItems.set(VIEW_TYPE.VIEW_PORT_FORWARDING, "Port Forwarding");
        this.mainMenuItems.set(VIEW_TYPE.VIEW_ACTIVITY, "Activity");
        this.mainMenuItems.set(VIEW_TYPE.VIEW_HEALTH, "Health");
        this.mainMenuItems.set(VIEW_TYPE.VIEW_SETTINGS, "Settings");
    }

    //region Private Fields

    private hasAuthToken: boolean = false;
    private dmsClient!: DMSWSClient;
    private dmsRestClient!: DMSRESTApiClient;

    private lastVerificationToken: string = "";

    private forceUpdateDebounced = Util.debounce(() => {
        this.forceUpdate();
    }, 500);

    //endregion

    //region React Methods

    public async componentDidMount() {
        window.addEventListener("focus", this.onFocus);

        await this.init();
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.onFocus);
    }

    public render() {
        if (this.state.selectedView === VIEW_TYPE.VIEW_DASH) {
            return <DMSDashView
                enqueueSnackbar={this.props.enqueueSnackbar}
                closeSnackbar={this.props.closeSnackbar}
                logItems={this.state.logItems}
                logLine={this.logLine}
                dmsClient={this.dmsClient}
                serverStats={this.state.serverStats}
                classes={this.props.classes}
                dmsUsers={this.state.dmsUsers}
                dmsNodes={this.state.dmsNodes}
                dmsRestClient={this.dmsRestClient}
                dmsOnlineNodes={this.state.dmsOnlineNodes}
                portRules={this.state.portRules}
                isLoadingNodes={this.state.isLoadingNodes}
                onLoadDataRequested={this.loadRegisteredDMSNodes}
                displaySnackbar={this.displaySnackbar}
                dmsFiles={this.state.dmsFiles}
                isLoadingPortRules={this.state.isLoadingPortRules}
                onLoadPortRulesRequested={this.loadPortRules}
                isLoadingFiles={this.state.isLoadingFiles}
                bannedNodes={this.state.bannedNodes}
                definedSoftware={this.state.definedSoftware}
            />;
        }
        return this.hasAuthToken ? this.renderDashboard() : <Navigate to="/login"/>;
    }

    //endregion

    //region Private Methods

    private isCurrentUserRoot = () => {
        return (this.state.currentUser?.memberOf ?? []).indexOf("root") > -1;
    };

    private init = async () => {
        console.log("initializing...");

        this.setState({isInitializing: true});

        const start = performance.now();

        let consoleEnabled = false;

        try {
            const jwtToken = this.getJwtToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return;
            }

            const env = Constants.env;

            const dmsScheme = EndpointHelper.getDmsScheme(env);
            const dmsHost = EndpointHelper.getDmsHostname(env);
            const dmsPortNumber = EndpointHelper.getDmsPortNumber(env);

            const useWss = dmsScheme === "https";

            this.dmsClient = new DMSWSClient(dmsHost, dmsPortNumber, useWss, Constants.dmsClientName, Constants.clientVersion, this);
            this.dmsRestClient = new DMSRESTApiClient(dmsScheme, dmsHost, dmsPortNumber);

            consoleEnabled = LocalStorageHelper.getConsoleEnabled();

            const loadDmsNodesPromise = this.loadRegisteredDMSNodes();
            const connectToDmsPromise = this.connectToDms();

            // fetch dms nodes from the db and connect to dms while blocking this thread
            await Promise.all([loadDmsNodesPromise, connectToDmsPromise]);

            const loadGlobalSettingsPromise = this.loadGlobalSettings();
            const loadFilesPromise = this.loadFiles();
            const loadUsersPromise = this.loadUsers();
            const loadUserGroupsPromise = this.loadUserGroups();
            const loadUserRolesPromise = this.loadUserRoles();
            const loadNotificationSettings = this.loadNotificationSettings();

            // execute asynchronously, do not block this thread
            Promise.all([loadGlobalSettingsPromise, loadFilesPromise, loadUsersPromise, loadUserGroupsPromise, loadUserRolesPromise, loadNotificationSettings]);
        } catch (e) {
            console.error("init failed: ", e);
        } finally {
            this.setState({isInitializing: false, isConsoleVisible: consoleEnabled});
            this.logLine(`${"loaded in " + (performance.now() - start)}ms`);
        }
    };

    private onFocus = () => {
        if (this.state.dmsConnectionState === DmsConnectionState.DISCONNECTED_FROM_DMS) {
            this.init();
        }
    };

    private mainMenuItems: Map<VIEW_TYPE, string> = new Map<VIEW_TYPE, string>();

    private renderIcons = (index: VIEW_TYPE) => {
        switch (index) {
            case VIEW_TYPE.VIEW_NODES:
                return <AppsIcon/>;
            case VIEW_TYPE.VIEW_API:
                return <Api/>;
            case VIEW_TYPE.VIEW_FILES:
                return <Description/>;
            case VIEW_TYPE.VIEW_USERS:
                return <PeopleIcon/>;
            case VIEW_TYPE.VIEW_PORT_FORWARDING:
                return <RouterIcon/>;
            case VIEW_TYPE.VIEW_ACTIVITY:
                return <HistoryIcon/>;
            case VIEW_TYPE.VIEW_HEALTH:
                return <LocalHospital/>;
            case VIEW_TYPE.VIEW_SETTINGS:
                return <SettingsIcon/>;
        }
    };

    private checkHasAccessToView = (viewType: VIEW_TYPE): boolean => {
        const isUserRoot = this.isCurrentUserRoot();

        switch (viewType) {
            case VIEW_TYPE.VIEW_NODES:
            case VIEW_TYPE.VIEW_USERS:
            case VIEW_TYPE.VIEW_ACTIVITY:
            case VIEW_TYPE.VIEW_HEALTH:
            case VIEW_TYPE.VIEW_SETTINGS:
            case VIEW_TYPE.VIEW_DASH:
                return true;
            case VIEW_TYPE.VIEW_API:
            case VIEW_TYPE.VIEW_FILES:
            case VIEW_TYPE.VIEW_PORT_FORWARDING:
            default:
                return isUserRoot;
        }
    };

    private renderDashboard() {
        const {classes} = this.props;
        const {isDrawerOpen} = this.state;

        const {dmsConnectionState} = this.state;

        // filter out main menu items that the user doesn't have access to
        const mainMenuItems = Array.from(this.mainMenuItems.entries()).map(([key, value], index) => {
            return {
                viewType: key,
                title: value,
                hasAccess: this.checkHasAccessToView(key)
            };
        });

        let connectionStateString = "Disconnected";

        const backdropIsOpen = this.state.isLoadingNodes || this.state.isLoadingFiles || this.state.isLoadingUsers || this.state.dmsConnectionState === DmsConnectionState.CONNECTING_TO_DMS;

        switch (dmsConnectionState) {
            case DmsConnectionState.CONNECTED_TO_DMS:
                connectionStateString = "Connected";
                break;
            case DmsConnectionState.INITIAL:
            case DmsConnectionState.CONNECTING_TO_DMS:
                connectionStateString = "Connecting";
                break;
            case DmsConnectionState.CONNECTION_TO_DMS_FAILED:
                connectionStateString = "Connection Failed";
                break;
            case DmsConnectionState.DISCONNECTED_FROM_DMS:
                connectionStateString = "Disconnected";
                break;
        }

        return (
            <div className={classes.root}>
                <CssBaseline/>
                {this.renderVerifyTwoFactorAuthDialog()}
                {
                    this.props.viewerMode ? undefined :
                        <AppHeader
                            twoFactorHandler={(twoFactorRequest: TwoFactorRequest) => {
                                this.setState({
                                    verify2FARequest: twoFactorRequest,
                                });
                            }}
                            cancelTwoFactorRequest={() => {
                                this.setState({
                                    verify2FARequest: undefined
                                });
                            }}
                            enqueueSnackbar={this.props.enqueueSnackbar}
                            closeSnackbar={this.props.closeSnackbar}
                            classes={classes}
                            dmsNodes={this.state.dmsNodes}
                            dmsOnlineNodes={this.state.dmsOnlineNodes}
                            dmsRestClient={this.dmsRestClient}
                            logLine={this.logLine}
                            currentUser={this.state.currentUser}
                            isDrawerOpen={this.state.isDrawerOpen}
                            selectedView={this.state.selectedView}
                            onToggleConsoleRequested={() => {
                                this.toggleConsole();
                            }}
                            onLogoutRequested={() => {
                                this.logout();
                            }}
                            handleDrawerOpen={() => {
                                this.setState({
                                    isDrawerOpen: true
                                });
                            }}
                        />
                }
                {
                    this.props.viewerMode ? undefined :
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={isDrawerOpen}
                            className={classes.drawer}
                            onClose={() => {
                                this.handleDrawerClose();
                            }}
                        >
                            <div className={classes.toolbar}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    {<ChevronLeftIcon/>}
                                </IconButton>
                            </div>
                            <Divider/>
                            <List>
                                {mainMenuItems.map((item, index) => (
                                    item.hasAccess ?
                                        <ListItem selected={this.state.selectedView === index} onClick={() => {
                                            this.setState({selectedView: index});
                                            LocalStorageHelper.setCurrentView(index);
                                            this.handleDrawerClose();
                                        }} button key={item.viewType}>
                                            <ListItemIcon>{
                                                this.renderIcons(index)
                                            }
                                            </ListItemIcon>
                                            <ListItemText primary={item.title}/>
                                        </ListItem> : undefined
                                ))}
                            </List>
                        </Drawer>
                }
                <main className={this.props.viewerMode ? classes.contentNoPadding : classes.content}>
                    {
                        this.props.viewerMode ? undefined
                            : <div className={classes.toolbar}/>
                    }
                    <Grid container
                          spacing={3}>
                        <Backdrop className={classes.backdrop}
                                  transitionDuration={250}
                                  unmountOnExit={true}
                                  open={backdropIsOpen}>
                            <Spinner style={{margin: "24px", transition: "color 500ms ease-in-out"}} fadeIn="half"
                                     color={dmsConnectionState === DmsConnectionState.CONNECTION_TO_DMS_FAILED ? "orange" : !this.state.isLoadingNodes ? "lightgreen" : "white"}
                                     name="ball-scale-multiple"/>
                            <Typography style={{margin: "8px", fontWeight: 200}}
                                        variant="caption">{connectionStateString}</Typography>

                            <Slide direction="up"
                                   in={this.state.currentUser !== undefined}
                                   style={{margin: 16}}>
                                <Fade in={this.state.currentUser !== undefined}>
                                    <div>
                                        <DMSUserView username={this.state.currentUser?.username ?? ""}
                                                     classes={classes}/>
                                    </div>
                                </Fade>
                            </Slide>

                        </Backdrop>
                        <Backdrop className={classes.backdrop}
                                  transitionDuration={500}
                                  unmountOnExit={true}
                                  open={this.state.globalSettings?.maintenanceMode ?? false}>
                            <div className={classes.backdropMessage}>
                                <EnhancedEncryptionIcon style={{margin: 16}} fontSize={"large"}/>
                                <Typography variant="h6">The application is in maintenance mode</Typography>
                                <Typography variant="caption">Please try reconnecting later</Typography>
                                <p/>
                                <Button
                                    disabled={this.state.dmsConnectionState === DmsConnectionState.CONNECTING_TO_DMS}
                                    onClick={this.init}
                                    variant="contained"
                                    startIcon={<RefreshIcon/>}>Connect</Button>
                            </div>
                        </Backdrop>
                        <Backdrop className={classes.backdrop}
                                  transitionDuration={500}
                                  unmountOnExit={true}
                                  open={!this.state.isLoadingNodes && (this.state.dmsConnectionState === DmsConnectionState.CONNECTION_TO_DMS_FAILED || this.state.dmsConnectionState === DmsConnectionState.DISCONNECTED_FROM_DMS)}>
                            <div className={classes.backdropMessage}>
                                <SignalCellularConnectedNoInternet0BarTwoToneIcon style={{margin: 16}}
                                                                                  fontSize={"large"}/>
                                <Typography variant="h6">Disconnected from DMS</Typography>
                                <Typography variant="caption">Please try reconnecting later</Typography>
                                <p/>
                                <Button
                                    disabled={this.state.dmsConnectionState === DmsConnectionState.CONNECTING_TO_DMS}
                                    onClick={this.init}
                                    variant="contained"
                                    startIcon={<RefreshIcon/>}>Connect</Button>
                            </div>
                        </Backdrop>
                        <div
                            style={{
                                position: "fixed",
                                left: 0,
                                bottom: 0,
                                right: 0,
                                zIndex: this.state.isConsoleVisible ? 1000000 : 0,
                                paddingTop: 8,
                            }}>
                            <Slide direction={"up"} unmountOnExit={true} in={this.state.isConsoleVisible}>
                                <div>
                                    <Console onCommand={(cmd: string) => {
                                        this.onCommand(cmd);
                                    }} caretHeader={this.state.consoleHeader}
                                             style={{backgroundColor: "rgba(0,0,0,0.90)"}}
                                             logItems={this.state.logItems}/>
                                </div>
                            </Slide>
                        </div>
                        {this.props.viewerMode ? undefined : this.renderServerStats()}
                        {
                            this.renderSelectedView()
                        }
                    </Grid>
                </main>
            </div>
        );
    }

    private renderServerStats = () => {
        const {serverStats, isLoadingServerStats, dmsNodes} = this.state;
        const {classes} = this.props;
        let serverVersionString = "";
        let serverUptimeString = "";

        const numberOfNodes = Object.keys(dmsNodes).length;

        if (serverStats && serverStats.serverVersion) {
            if (serverStats.serverVersion) {
                serverVersionString = `${this.state.serverStats!.serverVersion.major}.${this.state.serverStats!.serverVersion.minor}.${this.state.serverStats!.serverVersion.build}`;
            }

            if (serverStats?.uptimeSeconds) {
                serverUptimeString = moment.duration(serverStats?.uptimeSeconds, "seconds").humanize();
            }
        }

        return <Grid item xs={12} sm={12}>
            <Accordion expanded={this.state.serverStatsExpanded}
                       onChange={(e, expanded) => {
                           this.setState({serverStatsExpanded: expanded});
                           LocalStorageHelper.setServerStatsExpanded(expanded);
                       }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header">
                    <Typography className={classes.heading}>Server Stats</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Fade
                        in={this.state.serverStatsExpanded && (serverStats !== undefined || isLoadingServerStats)}
                        unmountOnExit={true}>
                        <Grid container
                              direction={"row"}
                              justifyContent="space-between"
                              alignItems="center">

                            <Grid item>
                                <Typography variant={"caption"}>Version</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> : <Typography
                                        variant={"subtitle2"}>{serverVersionString}</Typography>}
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>Environment</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> : <Typography
                                        variant={"subtitle2"}>{Constants.env}</Typography>}
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>Public IP</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> : <Typography
                                        variant={"subtitle2"}>{serverStats?.publicIp}</Typography>}
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>Free Memory</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> :
                                    <div>
                                        <LinearProgress
                                            variant={"determinate"}
                                            value={100 - (100 / (serverStats?.totalmem ?? 1) * (serverStats?.freemem ?? 1))}/>
                                        <Typography
                                            variant={"subtitle2"}>{`${serverStats?.freemem ? Util.formatBytes(serverStats.freemem) : ""} of ${serverStats?.totalmem ? Util.formatBytes(serverStats.totalmem) : ""}`}</Typography>
                                    </div>}
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>Avg. CPU Load</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> :
                                    <div>
                                        <LinearProgress
                                            variant={"determinate"}
                                            value={serverStats?.cpuUsage ?? 0}/>
                                        <Typography
                                            variant={"subtitle2"}>{`${Math.round(serverStats?.cpuUsage ?? 0)}%`}</Typography>
                                    </div>}
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>CPU</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> : <Typography
                                        variant={"subtitle2"}>{`${serverStats?.numCpus} CPUs - ${serverStats?.localCluster ? "IPC Cluster" : "Single Instance"}`}</Typography>}
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>OS</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> : <Typography
                                        variant={"subtitle2"}>{serverStats?.serverOS}</Typography>}
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>Connected Nodes</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> :
                                    <div>
                                        <LinearProgress
                                            variant={"determinate"}
                                            value={100 / (numberOfNodes ?? 1) * (serverStats?.numberOfClients ?? 1)}/>
                                        <Typography
                                            variant={"subtitle2"}>{`${serverStats?.numberOfClients} / ${numberOfNodes}`}</Typography>
                                    </div>}
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>Cluster Worker ID</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> :
                                    <Typography
                                        variant={"subtitle2"}>{`${serverStats?.currentWorker}`}</Typography>
                                }
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"}>Uptime</Typography>
                                {isLoadingServerStats ?
                                    <LinearProgress/> : <Typography
                                        variant={"subtitle2"}>{serverUptimeString}</Typography>}
                            </Grid>
                        </Grid>
                    </Fade>
                </AccordionDetails>
                <AccordionActions>
                    <IconButton disabled={this.state.isLoadingNodes} onClick={() => {
                        this.getServerStats();
                    }} aria-label="refresh" size="small">
                        <RefreshIcon/>
                    </IconButton>
                </AccordionActions>
            </Accordion>
        </Grid>;
    };

    private renderSelectedView = (): ReactElement<Object> => {
        const {classes, enqueueSnackbar, closeSnackbar} = this.props;
        const {selectedView} = this.state;

        switch (selectedView) {
            case VIEW_TYPE.VIEW_NODES:
                return <DMSNodesView
                    mode={"nodes"}
                    tableKey={"nodes-table"}
                    twoFactorHandler={(twoFactorRequest: TwoFactorRequest) => {
                        this.setState({
                            verify2FARequest: twoFactorRequest,
                        });
                    }}
                    cancelTwoFactorRequest={() => {
                        this.setState({
                            verify2FARequest: undefined
                        });
                    }}
                    classes={classes}
                    globalSettings={this.state.globalSettings}
                    dmsNodes={this.state.dmsNodes}
                    bannedNodes={this.state.bannedNodes}
                    dmsOnlineNodes={this.state.dmsOnlineNodes}
                    onLoadDataRequested={this.loadRegisteredDMSNodes}
                    dmsFiles={this.state.dmsFiles}
                    currentUser={this.state.currentUser}
                    isLoadingFiles={this.state.isLoadingFiles}
                    isLoadingNodes={this.state.isLoadingNodes}
                    renderFilesTable={() => null}
                    dmsClient={this.dmsClient}
                    dmsRestClient={this.dmsRestClient}
                    portRules={this.state.portRules}
                    logLine={this.logLine}
                    definedSoftware={this.state.definedSoftware}
                    onLoadPortRulesRequested={this.loadPortRules}
                    isLoadingPortRules={this.state.isLoadingPortRules}
                    enqueueSnackbar={enqueueSnackbar}
                    closeSnackbar={closeSnackbar}/>;
            case VIEW_TYPE.VIEW_FILES:
                return <DMSFilesView
                    dmsFiles={this.state.dmsFiles}
                    onLoadDataRequested={this.loadFiles}
                    logLine={this.logLine}
                    isLoadingFiles={this.state.isLoadingFiles}
                    dmsClient={this.dmsClient}
                    dmsRestClient={this.dmsRestClient}
                    classes={classes}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    closeSnackbar={this.props.closeSnackbar}/>;
            case VIEW_TYPE.VIEW_USERS:
                return <DMSUsersView
                    twoFactorHandler={(twoFactorRequest: TwoFactorRequest) => {
                        this.setState({
                            verify2FARequest: twoFactorRequest,
                        });
                    }}
                    cancelTwoFactorRequest={() => {
                        this.setState({
                            verify2FARequest: undefined
                        });
                    }}
                    isLoadingUsers={this.state.isLoadingUsers}
                    dmsUsers={this.state.dmsUsers}
                    dmsUserGroups={this.state.userGroups}
                    dmsUserRoles={this.state.userRoles}
                    dmsOnlineNodes={this.state.dmsOnlineNodes}
                    onLoadUsersRequested={this.loadUsers}
                    onLoadUserGroupsRequested={this.loadUserGroups}
                    onLoadUserRolesRequested={this.loadUserRoles}
                    logLine={this.logLine}
                    displaySnackbar={this.displaySnackbar}
                    dmsClient={this.dmsClient}
                    dmsRestClient={this.dmsRestClient}
                    classes={classes}
                    currentUser={this.state.currentUser}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    closeSnackbar={this.props.closeSnackbar}/>;
            case VIEW_TYPE.VIEW_PORT_FORWARDING:
                return <DMSPortForwardingRulesView
                    dmsNodes={this.state.dmsNodes}
                    dmsOnlineNodes={this.state.dmsOnlineNodes}
                    onLoadDataRequested={this.loadPortRules}
                    portRules={this.state.portRules}
                    isLoadingPortRules={this.state.isLoadingPortRules}
                    logLine={this.logLine}
                    displaySnackbar={this.displaySnackbar}
                    dmsClient={this.dmsClient}
                    classes={classes}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    closeSnackbar={this.props.closeSnackbar}/>;
            case VIEW_TYPE.VIEW_ACTIVITY:
                return <DMSUserActivityView
                    persistFilters={true}
                    newLogEvents={this.state.newLogEvents}
                    dmsOnlineNodes={this.state.dmsOnlineNodes}
                    dmsNodes={this.state.dmsNodes}
                    logLine={this.logLine}
                    displaySnackbar={this.displaySnackbar}
                    classes={classes}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    closeSnackbar={this.props.closeSnackbar}/>;
            case VIEW_TYPE.VIEW_SETTINGS:
                return <DMSSettingsView
                    logLine={this.logLine}
                    displaySnackbar={this.displaySnackbar}
                    classes={classes}
                    dmsClient={this.state.dmsNodes ? this.dmsClient : undefined}
                    dmsRestClient={this.dmsRestClient}
                    dmsNodes={this.state.dmsNodes}
                    dmsOnlineNodes={this.state.dmsOnlineNodes}
                    isLoadingDefinedSoftware={this.state.isLoadingDefinedSoftware}
                    isLoadingNotificationSettings={this.state.isLoadingNotificationSettings}
                    notificationSettings={this.state.notificationSettings}
                    definedSoftware={this.state.definedSoftware}
                    onLoadBannedNodesRequested={this.loadBannedNodes}
                    onLoadSoftwareRequested={this.loadDefinedSoftware}
                    onLoadGlobalSettingsRequested={this.loadGlobalSettings}
                    onLoadNotificationSettingsRequested={this.loadNotificationSettings}
                    isLoadingBannedNodes={this.state.isLoadingBannedNodes}
                    bannedNodes={this.state.bannedNodes}
                    globalSettings={this.state.globalSettings}
                    enqueueSnackbar={this.props.enqueueSnackbar}
                    closeSnackbar={this.props.closeSnackbar}/>;
            case VIEW_TYPE.VIEW_HEALTH:
                return <DMSHealthView
                    lightTheme={this.props.viewerMode}
                    authToken={this.props.authToken}
                    viewerMode={this.props.viewerMode}
                    globalSettings={this.state.globalSettings}
                    dmsNodes={this.state.dmsNodes}
                    bannedNodes={this.state.bannedNodes}
                    dmsOnlineNodes={this.state.dmsOnlineNodes}
                    onLoadDataRequested={this.loadRegisteredDMSNodes}
                    dmsFiles={this.state.dmsFiles}
                    currentUser={this.state.currentUser}
                    isLoadingFiles={this.state.isLoadingFiles}
                    isLoadingNodes={this.state.isLoadingNodes}
                    renderFilesTable={() => null}
                    dmsClient={this.dmsClient}
                    dmsRestClient={this.dmsRestClient}
                    logLine={this.logLine}
                    definedSoftware={this.state.definedSoftware}
                    classes={classes}
                    enqueueSnackbar={enqueueSnackbar}
                    closeSnackbar={closeSnackbar}/>;
            case VIEW_TYPE.VIEW_API:
                return <DMSAPIClientsView
                    apiUsers={this.state.apiUsers}
                    twoFactorHandler={(twoFactorRequest: TwoFactorRequest) => {
                        this.setState({
                            verify2FARequest: twoFactorRequest,
                        });
                    }}
                    cancelTwoFactorRequest={() => {
                        this.setState({
                            verify2FARequest: undefined
                        });
                    }}
                    classes={classes}
                    globalSettings={this.state.globalSettings}
                    bannedNodes={this.state.bannedNodes}
                    dmsOnlineNodes={this.state.dmsOnlineNodes}
                    onLoadDataRequested={this.loadUsers}
                    dmsFiles={this.state.dmsFiles}
                    currentUser={this.state.currentUser}
                    isLoadingFiles={this.state.isLoadingFiles}
                    isLoadingNodes={this.state.isLoadingNodes}
                    renderFilesTable={() => null}
                    dmsClient={this.dmsClient}
                    dmsRestClient={this.dmsRestClient}
                    portRules={this.state.portRules}
                    logLine={this.logLine}
                    definedSoftware={this.state.definedSoftware}
                    onLoadPortRulesRequested={this.loadPortRules}
                    isLoadingPortRules={this.state.isLoadingPortRules}
                    enqueueSnackbar={enqueueSnackbar}
                    closeSnackbar={closeSnackbar}
                    dmsUserGroups={this.state.userGroups}/>;
            default:
                return (<b> {`VIEW_TYPE: ${selectedView} `} NOT IMPLEMENTED</b>);
        }
    };

    private handleDrawerOpen = () => {
        this.setState({isDrawerOpen: true});
    };

    private handleDrawerClose = () => {
        this.setState({isDrawerOpen: false});
    };

    private toggleConsole = () => {
        this.setState({
            isConsoleVisible: !this.state.isConsoleVisible
        }, () => {
            LocalStorageHelper.setConsoleEnabled(this.state.isConsoleVisible);
        });
    };

    private onCommand = async (cmd: string) => {
        if (!cmd || cmd.length < 1) {
            return;
        }

        this.logLine(`${this.state.consoleHeader} ${cmd}`);

        switch (cmd.toLowerCase()) {
            case "help":
                this.logLine("available commands:");
                this.logLine("clear|cls|");
                this.logLine("ls");
                this.logLine("ping %node%");
                break;
            case "ls":
                const nodesKeys = Object.keys(this.state.dmsNodes);
                nodesKeys.forEach(n => {
                    const isNodeOnline = Boolean(this.state.dmsOnlineNodes[n]);

                    const location = this.state.dmsNodes[n].location;

                    this.logLine(`${n}@${location} ${isNodeOnline ? " - ONLINE" : " - OFFLINE"}`);
                });
                this.logLine("");
                this.logLine(`${this.state.dmsNodes.size} node(s)`);
                break;
            case "cls":
            case "clear":
                this.setState({logItems: []});
                break;
            case "exit":
                if (this.state.isConsoleVisible) {
                    this.toggleConsole();
                }
                break;
            default:
                if (cmd.toLowerCase().startsWith("ping ")) {
                    let pingStart = performance.now();
                    try {
                        const targetNode = cmd.toLowerCase().replace("ping ", "");
                        this.logLine("pinging " + targetNode);
                        const pingMessage = DMSMessageFactory.newMessage<void>(DMSMethod.PING, undefined, targetNode);
                        await this.dmsClient.sendMessage(pingMessage, true);

                        this.logLine(`node '${targetNode}' successfully responded. (${performance.now() - pingStart}ms)`);
                    } catch (err) {
                        this.logLine(`failed to ping node. ${JSON.stringify(err)}`);
                    }


                } else {
                    this.logLine(`dms: command not found: ${cmd}`);
                }
                break;
        }
    };

    private getJwtToken = () => {
        const {viewerMode, authToken} = this.props;

        if (viewerMode) {
            return authToken!;
        }

        return LocalStorageHelper.getAuthToken();
    };

    private getServerStats = async () => {
        try {
            this.setState({isLoadingServerStats: true});

            const getStatsMessage = DMSMessageFactory.newMessage<void>(DMSMethod.GET_SERVER_STATS);
            const result = await this.dmsClient.sendMessage<IDMSResult<IDMSServerStats>>(getStatsMessage, true);

            this.setState({
                serverStats: result!.result,
                isLoadingServerStats: false
            });

            console.log("got server stats: ", result!.result);
        } catch (err) {
            this.logLine(`could not get server stats: ${err}`);

            this.setState({
                isLoadingServerStats: false
            });
        }
    };

    private parseJwt = (token: string) => {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    };

    private loadRegisteredDMSNodes = async () => {
        // gets the registered dms nodes from the database, this way we can display offline nodes too

        try {
            this.setState({isLoadingNodes: true});

            console.log("getting registered nodes");
            const jwtToken = this.getJwtToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return;
            }

            const jwtPayload = this.parseJwt(jwtToken);

            this.setState({
                currentUser: {
                    username: jwtPayload.uid
                } as any
            });

            const me = await this.dmsRestClient.getMe(jwtToken);
            this.setState({currentUser: me});

            const dbNodes = await this.dmsRestClient.getNodes(jwtToken);

            const dmsNodes: Map<string, IDBNode> = new Map<string, IDBNode>();

            dbNodes.forEach(n => {
                dmsNodes[n.uid] = n;
            });

            this.logLine(`logged in as ${me!.username}`);

            this.setState({
                consoleHeader: `${me.username ?? ""} ~ %`,
                userInitials: me ? this.getInitials(me.username) : "",
                dmsNodes,
                isLoadingNodes: false,
            }, () => {
            });
        } catch (error: any) {
            if (error && error.response && error.response.status === 401) {
                //Unauthorized
                this.displaySnackbar("Unauthorized", "error");
                LocalStorageHelper.setAuthToken(null);
                return;
            }

            if (error && error.response && error.response.status === 403) {
                //Unauthorized
                this.displaySnackbar("Banned", "error");
                LocalStorageHelper.setAuthToken(null);
                return;
            }

            console.log("failed to get current user's info: ", error);
            if (error instanceof TypeError) {
                return;
            }

            this.displaySnackbar("Could not get registered nodes! " + error.toString(), "error");
        }
    };

    private loadGlobalSettings = () => {
        const jwtToken = this.getJwtToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        const loadGlobalSettingsPromise = this.dmsRestClient.getGlobalSettings(jwtToken);
        loadGlobalSettingsPromise.then((globalSettings: IDMSSettings) => {
            if (globalSettings) {
                this.setState({globalSettings});
            }
        }).catch((err) => {
            console.error("failed to load global settings: ", err);
        });

        return loadGlobalSettingsPromise;
    };

    private loadFiles = async () => {
        // gets the registered dms nodes from the database, this way we can display offline nodes too

        let dmsFiles: IDMSFile[] = [];

        try {
            this.setState({isLoadingFiles: true});

            console.log("getting files");
            const jwtToken = this.getJwtToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return;
            }

            dmsFiles = await this.dmsRestClient.listFilesFromBucket(jwtToken);
        } catch (error: any) {
            console.log("failed to get the files: ", error);
            if (error instanceof TypeError) {
                return;
            }

            this.displaySnackbar("Could not get the list of files! " + error.toString(), "error");
            return;
        } finally {
            this.setState({
                dmsFiles,
                isLoadingFiles: false,
            });
        }
    };

    private loadUsers = async () => {
        // gets the registered dms nodes from the database, this way we can display offline nodes too

        let dmsUsers: IDBUser[] = [];
        let apiUsers: IDBUser[] = [];

        try {
            this.setState({isLoadingUsers: true});


            console.log("loading users");
            const jwtToken = this.getJwtToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return;
            }

            dmsUsers = await this.dmsRestClient.getAppUsers(jwtToken);

            apiUsers = await this.dmsRestClient.getApiUsers(jwtToken);
        } catch (error: any) {
            console.log("failed to get the users: ", error);
            if (error instanceof TypeError) {
                return;
            }

            this.displaySnackbar("Could not get registered users! " + error.toString(), "error");
            return;
        } finally {
            this.setState({
                dmsUsers,
                apiUsers,
                isLoadingUsers: false,
            });
        }
    };

    private loadUserGroups = async () => {
        // gets the registered dms nodes from the database, this way we can display offline nodes too

        let userGroups: IDBUserGroup[] | undefined = [];

        try {
            this.setState({isLoadingUserGroups: true});

            console.log("loading user groups");
            const jwtToken = this.getJwtToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return;
            }

            userGroups = await this.dmsRestClient.getUserGroups(jwtToken);
        } catch (error: any) {
            console.log("failed to get user groups: ", error);
            if (error instanceof TypeError || error.response?.status === 403) {
                userGroups = undefined;
                return;
            }

            this.displaySnackbar("Could not get user groups " + error.toString(), "error");
            return;
        } finally {
            this.setState({
                userGroups,
                isLoadingUserGroups: false,
            });
        }
    };

    private loadUserRoles = async () => {
        // gets the registered dms nodes from the database, this way we can display offline nodes too

        let userRoles: IDBUserRole[] | undefined = [];

        try {
            this.setState({isLoadingUserRoles: true});

            console.log("loading user roles");
            const jwtToken = this.getJwtToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return;
            }

            userRoles = await this.dmsRestClient.getUserRoles(jwtToken);
        } catch (error: any) {
            console.log("failed to get user roles: ", error);
            if (error instanceof TypeError || error.response?.status === 403) {
                userRoles = undefined;
                return;
            }

            this.displaySnackbar("Could not get user roles " + error.toString(), "error");
            return;
        } finally {
            this.setState({
                userRoles,
                isLoadingUserRoles: false,
            });
        }
    };

    private loadNotificationSettings = async () => {
        let notificationSettings: IDBNotificationSubscription[] = [];

        try {
            this.setState({isLoadingNotificationSettings: true});

            console.log("loading notification settings");
            const jwtToken = this.getJwtToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return;
            }

            notificationSettings = await this.dmsRestClient.getNotificationSubscriptions(jwtToken);
        } catch (error: any) {
            console.log("failed to get notification settings: ", error);
            if (error instanceof TypeError) {
                return;
            }

            this.displaySnackbar("Could not get notification settings! " + error.toString(), "error");
            return;
        } finally {
            this.setState({
                notificationSettings,
                isLoadingNotificationSettings: false,
            });
        }
    };

    private loadPortRules = async () => {
        let portRules: IPortForwardingRule[] = [];

        const {classes} = this.props;

        try {
            this.setState({isLoadingPortRules: true});

            const getPortRulesMessage = DMSMessageFactory.newMessage<void>(DMSMethod.GET_PORT_FORWARDING_RULES);
            const result = await this.dmsClient.sendMessage<IDMSResult<IPortForwardingRule[]>>(getPortRulesMessage, true);

            if (result?.error) {
                throw result!.error;
            }

            portRules = result?.result ?? [];

            if (this.isCurrentUserRoot() && LocalStorageHelper.getPortForwardingNotificationsEnabled() && this.state.portRules.length > 0) {
                const enabledPortRulesOld = this.state.portRules.filter(p => p.isEnabled);
                const enabledPortRulesNew = portRules.filter(p => p.isEnabled);

                //const disabledPortRulesOld = this.state.portRules.filter(p => !p.isEnabled);
                const disabledPortRulesNew = portRules.filter(p => !p.isEnabled);

                const oldEnabledIds = new Set(enabledPortRulesOld.map(p => p.targetNode));
                //const newEnabledIds = new Set(enabledPortRulesNew.map(p => p.targetNode));

                const newlyEnabledPortRules = enabledPortRulesNew.filter(p => !oldEnabledIds.has(p.targetNode));
                const newlyDisabledPortRules = disabledPortRulesNew.filter(p => oldEnabledIds.has(p.targetNode));

                for (const newlyEnabledPortRule of newlyEnabledPortRules) {
                    this.displaySnackbar(
                        <div>
                            <DMSUserView chipSize="small" classes={classes} username={newlyEnabledPortRule.enabledBy!}/>
                            {` enabled port forwarding for: '${newlyEnabledPortRule.targetNode}@${newlyEnabledPortRule.nodeLocation}'`}
                        </div> as any,
                        "success",
                        {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    );
                }

                for (const newlyDisabledPortRule of newlyDisabledPortRules) {
                    this.displaySnackbar(
                        <div>
                            <DMSUserView chipSize="small" classes={classes}
                                         username={newlyDisabledPortRule.enabledBy!}/>
                            {` disabled port forwarding for: '${newlyDisabledPortRule.targetNode}@${newlyDisabledPortRule.nodeLocation}'`}
                        </div> as any,
                        "warning", {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    );
                }
            }

        } catch (error: any) {
            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to get port forwarding rules Error: ${JSON.stringify(error)}`;
            }

            this.logLine(`something went wrong when getting port forwarding rules: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        } finally {
            this.setState({isLoadingPortRules: false, portRules});
        }
    };

    private loadDefinedSoftware = async () => {
        let definedSoftware: IDMSSoftwareBundle[] = [];

        try {
            this.setState({isLoadingDefinedSoftware: true});

            const getSoftwareMsg = DMSMessageFactory.newMessage<void>(DMSMethod.GET_DEFINED_SOFTWARE);
            const result = await this.dmsClient.sendMessage<IDMSResult<IDMSSoftwareBundle[]>>(getSoftwareMsg, true);

            if (result?.error) {
                throw result!.error;
            }

            definedSoftware = result?.result ?? [];
        } catch (error: any) {
            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to get defined software Error: ${JSON.stringify(error)}`;
            }

            this.logLine(`something went wrong when getting defined software: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        } finally {
            this.setState({isLoadingDefinedSoftware: false, definedSoftware});
        }
    };

    private loadBannedNodes = async () => {
        let bannedNodes: IDMSNodeBanState[] = [];

        try {
            this.setState({isLoadingBannedNodes: true});

            const getBannedNodes = DMSMessageFactory.newMessage<void>(DMSMethod.GET_BANNED_NODES);
            const result = await this.dmsClient.sendMessage<IDMSResult<IDMSNodeBanState[]>>(getBannedNodes, true);

            if (result?.error) {
                throw result!.error;
            }

            bannedNodes = result?.result ?? [];
        } catch (error: any) {
            if (error.requiresRoot) {
                return;
            }

            let errorText: string;

            if ("description" in error) {
                errorText = error.description;
            } else {
                errorText = `failed to get banned nodes Error: ${JSON.stringify(error)}`;
            }

            this.logLine(`something went wrong when getting banned nodes: ${errorText}`);
            this.displaySnackbar(errorText, "error");
        } finally {
            this.setState({isLoadingBannedNodes: false, bannedNodes});
        }
    };

    private connectToDms = async () => {
        try {
            await this.setState({
                dmsConnectionState: DmsConnectionState.CONNECTING_TO_DMS,
            });

            const jwtToken = this.getJwtToken();

            if (jwtToken === null) {
                console.log("missing jwt token");
                return;
            }

            // register local callables

            this.dmsClient.registerCallable(DMSMethod.SETTINGS_CHANGED, {
                implementation: this.onDMSSettingsChanged
            });

            this.dmsClient.registerCallable(DMSMethod.PORT_FORWARDING_RULES_CHANGED, {
                implementation: this.onDMSPortForwardingRulesChanged
            });

            this.dmsClient.registerCallable(DMSMethod.NODE_INFO_ONLINE, {
                implementation: this.onNodeOnline,
            });

            this.dmsClient.registerCallable(DMSMethod.NODE_INFO_OFFLINE, {
                implementation: this.onNodeOffline,
            });

            this.dmsClient.registerCallable(DMSMethod.NODE_INFO_UPDATED, {
                implementation: this.onNodeInfoUpdated
            });

            // USED TO SIMULATE NOTIFICATIONS
            /*const fn = () => {
                const dbNode: IDBNode = {
                    clientName: "dev-node",
                    location: "bbc",
                    uid: "dev-node",
                    lastIp: "0.0.0.0",
                    operatingSystem: "macOS",

                }

                const nodeInfoUpdateMsg: IDMSMessage<IDBNode> = {
                    method: DMSMethod.NODE_INFO_UPDATED,
                    payload: dbNode
                }

                this.onNodeInfoUpdated(nodeInfoUpdateMsg);

                const randomBoolean = Math.random() < 0.5;

                if (randomBoolean) {
                    this.onNodeOnline(nodeInfoUpdateMsg);
                } else {
                    this.onNodeOffline(nodeInfoUpdateMsg);
                }

                setTimeout(fn, 20);
            }

            fn();*/

            this.dmsClient.registerCallable<IDMSSoftwareUpdateProgress, void>(DMSMethod.SOFTWARE_UPDATE_PROGRESS, {
                implementation: (msg) => {
                    const targetNode = this.state.dmsNodes[msg.payload!.nodeId];
                    if (targetNode) {
                        targetNode["updateProgress"] = msg.payload!.progress;
                        this.forceUpdateDebounced();
                    }
                }
            });

            this.dmsClient.registerCallable<IDMSBridgeNotification, void>(DMSMethod.NODE_BRIDGE_NOTIFICATION, {
                implementation: (msg, sender: IDMSNode) => {
                    if (!msg.payload) {
                        return;
                    }

                    const sendingNode = this.state.dmsNodes[msg!.payload!.sender] as IDBNode;

                    if (sendingNode) {
                        if (msg.payload?.data?.length > 0) {
                            sendingNode.lastBridgeMessage = msg.payload.data[0];
                        }

                        this.forceUpdateDebounced();
                    }
                }
            });

            await this.dmsClient!.connect({
                jwtToken,
            } as IDMSClientConfig);
        } catch (error) {
            console.log("connection to dms failed: ", error);
            this.setState({dmsConnectionState: DmsConnectionState.CONNECTION_TO_DMS_FAILED});
        }
    };

    private logout = () => {
        const jwtToken = this.getJwtToken();

        if (jwtToken === null) {
            console.log("missing jwt token");
            return;
        }

        // invalidate user's jwt token
        this.dmsRestClient.invalidate(jwtToken, jwtToken);

        LocalStorageHelper.setAuthToken(null);
        this.hasAuthToken = false;

        this.forceUpdate();
    };

    private logLine = (line: string) => {
        const {isConsoleVisible} = this.state;

        if (isConsoleVisible) {
            console.log(line);
        }

        this.state.logItems.push(line);
    };

    private displaySnackbar = (message: string | ReactElement, variant: VariantType = "info", origin: SnackbarOrigin = {
        vertical: "top",
        horizontal: "center"
    }) => {
        this.props.enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: origin
        });
    };

    private getInitials = (name: string) => {
        const names = name.split(" ");
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    //endregion

    //region IDMSClientDelegate

    public onConnectedToDms = async (sender: DMSWSClient) => {
        this.logLine("connected to dms");
    };

    public onConnectionAcknowledged = async (sender: DMSWSClient) => {
        this.logLine("connection acknowledged");

        const listClientsMessage = DMSMessageFactory.newMessage<void>(DMSMethod.CLIENTS_LIST, undefined);
        const dmsClients = await this.dmsClient.sendMessage<IDMSResult<IDMSNode[]>>(listClientsMessage, true);

        await this.loadDefinedSoftware();

        this.logLine(`${dmsClients?.result ? dmsClients.result.length : 0} node(s) are currently connected to DMS`);

        const dmsOnlineNodes: Map<string, IDMSNode> = new Map<string, IDBNode>();

        (dmsClients?.result ?? []).forEach(c => {
            if (c?.uid) {
                dmsOnlineNodes[c.uid] = c;
            }
        });

        this.setState({
            dmsConnectionState: DmsConnectionState.CONNECTED_TO_DMS,
            dmsOnlineNodes
        }, () => {
            this.getServerStats();
            this.loadPortRules();
            this.loadBannedNodes();
        });

        return undefined;
    };

    public onDisconnectedFromDms = (sender: DMSWSClient) => {
        this.logLine("Disconnected from DMS");

        this.setState({
            dmsConnectionState: DmsConnectionState.DISCONNECTED_FROM_DMS
        });
    };

    public onConnectionFailed = (sender: DMSWSClient) => {
        this.logLine("Could not connect to DMS");

        this.setState({
            dmsConnectionState: DmsConnectionState.CONNECTION_TO_DMS_FAILED
        });
    };

    public onData = (sender: DMSWSClient, data: Buffer) => {
        console.log("onData: " + data);
    };

    //endregion

    //region Local Callables

    private onDMSSettingsChanged = Util.debounce((message: IDMSMessage<IDMSSettings>) => {
        this.logLine(`got new settings: ${JSON.stringify(message.payload)}`);
        this.setState({
            globalSettings: message.payload
        });
    }, 100);

    private onDMSPortForwardingRulesChanged = Util.debounce((message: IDMSMessage<void>) => {
        this.logLine(`the port forwarding rules have changed`);
        this.loadPortRules();
    }, 100);

    private updateDmsOnlineNodesThrottled = Util.throttle((dmsOnlineNodes: Map<string, IDMSNode>) => {
        this.setState({
            dmsOnlineNodes
        });
    }, 500);

    private updateDmsNodesThrottled = Util.throttle((dmsNodes: Map<string, IDBNode>, newLogEvents: number) => {
        this.setState({
            dmsNodes,
            newLogEvents
        });
    }, 500);

    private onNodeOnline = (message: IDMSMessage<IDMSNode>) => {
        const {classes} = this.props;

        const connectedNode = message.payload!;

        const isNodeOnline = this.state.dmsOnlineNodes[connectedNode.uid!];

        this.logLine(`node '${connectedNode.uid}' is now online`);

        const {dmsOnlineNodes} = this.state;

        let shouldUpdateState = false;

        if (isNodeOnline) {
        } else {
            shouldUpdateState = true;
            dmsOnlineNodes[connectedNode.uid!] = connectedNode;
        }

        if (this.isCurrentUserRoot() && LocalStorageHelper.getUserOnlineNotificationsEnabled() && connectedNode.isAppUser) {
            this.displaySnackbar(
                <div>
                    <DMSUserView
                        isUserOnline={username => true}
                        chipSize="small"
                        classes={classes}
                        username={connectedNode.uid!}/>{` connected`}
                </div>,
                "success",
                {
                    vertical: "bottom",
                    horizontal: "left"
                }
            );
        }

        if (shouldUpdateState) {
            this.updateDmsOnlineNodesThrottled(dmsOnlineNodes);
        }
    };

    private onNodeOffline = (message: IDMSMessage<IDBNode>) => {
        const {classes} = this.props;

        const disconnectedNode = message.payload!;
        const onlineNode = this.state.dmsOnlineNodes[disconnectedNode.uid];

        this.logLine(`node '${disconnectedNode.uid}' is now offline`);

        let shouldUpdateState = false;

        const {dmsOnlineNodes} = this.state;

        if (onlineNode) {
            shouldUpdateState = true;
            delete dmsOnlineNodes[disconnectedNode.uid];
        }

        if (this.isCurrentUserRoot() && onlineNode.isAppUser && LocalStorageHelper.getUserOnlineNotificationsEnabled()) {
            this.displaySnackbar(
                <div>
                    <DMSUserView
                        isUserOnline={username => false}
                        chipSize="small"
                        classes={classes}
                        username={disconnectedNode.uid!}/>{` left`}
                </div>,
                "info",
                {
                    vertical: "bottom",
                    horizontal: "left"
                }
            );
        }

        if (this.state.nodeManagingSession?.targetNode && this.state.nodeManagingSession.targetNode!.uid === disconnectedNode.uid) {
            this.setState({nodeManagingSession: undefined});
            this.displaySnackbar(`Node '${disconnectedNode.uid}' disconnected`, "warning");
        } else {
            if (shouldUpdateState) {
                this.updateDmsOnlineNodesThrottled(dmsOnlineNodes);
            }
        }
    };

    private onNodeInfoUpdated = (message: IDMSMessage<IDBNode>) => {
        const updatedNode = message.payload! as IDBNode;

        updatedNode["updated"] = true;

        const lastLogEntry = updatedNode.lastLogEntry;

        this.logLine(`node ${updatedNode.uid}'s got a new state: '${lastLogEntry?.state ?? ""}'`);

        const isNodeOnline = Boolean(this.state.dmsOnlineNodes[updatedNode.uid]);

        const {dmsNodes} = this.state;

        let shouldUpdateState = false;

        if (isNodeOnline) {
            dmsNodes[updatedNode.uid] = updatedNode;
            shouldUpdateState = true;
        }

        if (shouldUpdateState) {
            if (updatedNode.uid === this.state.nodeInfoDialogState?.targetNode?.uid) {
                this.setState(prevState => ({
                    nodeInfoDialogState: {
                        ...prevState.nodeInfoDialogState,
                        targetNode: updatedNode
                    },
                }));
            } else {
                this.updateDmsNodesThrottled(dmsNodes, this.state.newLogEvents + 1);
            }
        }
    };

    private renderVerifyTwoFactorAuthDialog = () => {
        const verify2FARequest = this.state.verify2FARequest;

        if (!verify2FARequest) {
            return;
        }

        return (
            <ModalDialog open={this.state.verify2FARequest !== undefined}
                         title={"🔑 Two-factor authentication"}
                         subtitle={verify2FARequest.description}
                         buttonOkTitle={"Send"}
                         hideOkButton={true}
                         buttonCancelDisabled={verify2FARequest.isLoading}
                         onCancel={() => {
                             verify2FARequest?.onCancelled();

                             this.setState({
                                 verify2FARequest: undefined,
                                 password: ""
                             });
                         }}>
                <Container>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <ReactCodeInput
                            loading={verify2FARequest?.isLoading}
                            disabled={verify2FARequest?.isLoading}
                            onComplete={async (token) => {
                                verify2FARequest?.onGotToken(token);
                            }}/>
                        <FormHelperText
                            style={{
                                textAlign: "center",
                                margin: 8
                            }}>Please enter the auth token from your authenticator app</FormHelperText>
                    </div>
                </Container>
            </ModalDialog>);
    };

    //endregion
}

export default withStyles(styles, {withTheme: true})(withSnackbar(App));
