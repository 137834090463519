import IDataRendererMapping from "./classes/IDataRendererMapping";

const dataMappings = new Map<string, IDataRendererMapping>();

dataMappings.set("tagomat", {
    converter: "config.properties",
    sections: [{
        title: "Languages",
        metadata: {
            maxColumns: 4
        },
        items: [{
            field: "view.languages",
            metadata: {
                type: "language"
            }
        }]
    }]
});

dataMappings.set("bagomat", {
    converter: "config.properties",
    sections: [
        {
            title: "Languages",
            metadata: {
                maxColumns: 4
            },
            items: [{
                field: "abd.languages",
                metadata: {
                    type: "language"
                }
            }]
        },
        {
            title: "Airlines",
            metadata: {
                maxColumns: 1
            },
            items: [{
                field: "abd.airlines",
                metadata: {
                    type: "airline_logo",
                    separator: ";"
                }
            }]
        },
        {
            title: "Scale",
            metadata: {
                maxColumns: 2
            },
            items: [{
                title: "Min Weight",
                field: "bagdrop.conveyor.weight.min",
                metadata: {
                    type: "input",
                    readonly: true
                }
            }, {
                title: "Max Weight",
                field: "bagdrop.conveyor.weight.max",
                metadata: {
                    type: "input",
                    readonly: true
                }
            }]
        }
    ]
});

dataMappings.set("tagomat_v2", {
    converter: "json",
    sections: [
        {
            title: "Tagomat Info",
            metadata: {
                maxColumns: 4
            },
            items: [
                {
                    field: "Name",
                    title: "Name",
                    metadata: {
                        type: "input",
                        readonly: true
                    }
                },
                {
                    field: "AirportCode",
                    title: "✈️ Airport Code",
                    metadata: {
                        type: "input",
                        readonly: true
                    }
                },
                {
                    field: "Terminal",
                    title: "🛄 Terminal",
                    metadata: {
                        type: "input",
                        readonly: true
                    }
                },
                {
                    field: "TgmType",
                    metadata: {
                        type: "input",
                        readonly: true
                    }
                },
                {
                    field: "UnlimitedAllowance",
                    title: "🧳 Unlimited Allowance",
                    metadata: {
                        type: "switch",
                        readonly: true
                    }
                },
                {
                    field: "SecurityQuestionsConfig.Enabled",
                    title: "👮‍ Security Questions",
                    metadata: {
                        type: "switch",
                        readonly: true
                    }
                }
            ]
        },
        {
            title: "Languages",
            metadata: {
                maxColumns: 4
            },
            items: [{
                field: "Languages",
                metadata: {
                    type: "language",
                }
            }]
        },
        {
            title: "LED",
            metadata: {
                maxColumns: 4
            },
            items: [
                {
                    field: "LedConfig.Enabled",
                    title: "💡Led Enabled",
                    metadata: {
                        type: "switch",
                        readonly: true
                    }
                },
                {
                    field: "LedConfig.BrightnessLevel",
                    title: "🔅 Brightness level",
                    metadata: {
                        type: "input",
                        readonly: true
                    }
                }]
        },
        {
            title: "Scale",
            metadata: {
                maxColumns: 4
            },
            items: [{
                field: "ScaleConfig.Id",
                title: "Id",
                metadata: {
                    type: "input",
                    readonly: true
                }
            },
                {
                    field: "ScaleConfig.Enabled",
                    title: "Enabled",
                    metadata: {
                        type: "switch",
                        readonly: true
                    }
                },
                {
                    field: "ScaleConfig.PortName",
                    title: "Port Name",
                    metadata: {
                        type: "input",
                        readonly: true
                    }
                },
                {
                    field: "ScaleConfig.MaxWeight",
                    title: "Max Weight",
                    metadata: {
                        type: "input",
                        readonly: true
                    }
                }]
        },
        {
            title: "Passport Reader",
            metadata: {
                maxColumns: 4
            },
            items: [
                {
                    field: "PassportReaderConfig.Id",
                    title: "Id",
                    metadata: {
                        type: "input",
                        readonly: true
                    }
                },
                {
                    field: "PassportReaderConfig.Enabled",
                    title: "Enabled",
                    metadata: {
                        type: "switch",
                        readonly: true
                    }
                },
                {
                    field: "PassportReaderConfig.HandlesBarcodes",
                    title: "Handles Barcodes",
                    metadata: {
                        type: "switch",
                        readonly: true
                    }
                },
                {
                    field: "PassportReaderConfig.SkipPassportValidation",
                    title: "Skip Passport Validation",
                    metadata: {
                        type: "switch",
                        readonly: true
                    }
                }
            ]
        },
        {
            title: "Measure Device",
            metadata: {
                maxColumns: 4
            },
            items: [{
                field: "MeasureConfig.Id",
                title: "Id",
                metadata: {
                    type: "input",
                    readonly: true
                }
            }, {
                field: "MeasureConfig.Enabled",
                title: "Enabled",
                metadata: {
                    type: "switch",
                    readonly: true
                }
            }]
        }, {
            title: "Payment",
            metadata: {
                maxColumns: 4
            },
            items: [{
                field: "PaymentConfig.Enabled",
                title: "Enabled",
                metadata: {
                    type: "switch",
                    readonly: true
                }
            }]
        }, {
            title: "Airlines",
            metadata: {
                maxColumns: 1
            },
            items: [{
                field: "EnabledCarriers",
                metadata: {
                    type: "airline_logo"
                }
            }]
        }]
});


export default dataMappings;